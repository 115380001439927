<template>
  <ul class="en-m-goods-columns-skeleton">
      <li v-for="(item, index) in 6" :key="index" :class="{'bg': columns != 1}">
        <!-- 列表格式为 一行一列 -->
        <div class="goods-columns-1" v-if="columns == 1">
          <div class="goods-img"></div>
          <div class="goods-info">
            <p class="goods-title"></p>
            <div class="goods-price">
              <div></div>
              <div class="cart"></div>
            </div>
          </div>
        </div>

        <!-- 列表格式为 一行二列 -->
        <div class="goods-columns-2" v-else-if="columns == 2">
            <div class="goods-img"></div>
            <div class="goods-title">
                <p></p>
                <p></p>
            </div>
        </div>

        <!-- 列表格式为 一行三列 -->
        <div to="/en/goods/detail/1" class="goods-columns-3" v-else>
            <div class="goods-img"></div>
            <div class="goods-title">
                <p></p>
                <p></p>
            </div>
        </div>
      </li>
  </ul>
</template>

<script>
export default {
  name:'Skeleton',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
    columns: {
      type: Number,
      default: 2,
    }
  },
  methods:{

  }
}
</script>

<style lang="less" scoped>
.en-m-goods-columns-skeleton {width: 100%;display: flex;flex-wrap: wrap;justify-content: space-between;
position: relative;z-index: 9;padding-top:10px;animation: play 1s linear infinite alternate;
    >li{position: relative}
    .bg {margin-bottom: 16px;border-radius: 5px;}
    .goods-columns-1 {
        background: #fff;
        width: 343px;
        display: flex;
        box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);
        border-radius:5px;
        margin-bottom: 25px;
        padding:10px;
        opacity: 0.8;
        .goods-img {
            width: 120px;
            height: 120px;
            border-radius:5px;
            margin-right: 11px;
            margin-top:-20px;
            background-color: #f1f1f1;
        }
        .goods-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            padding-top: 10px;
        }
        .goods-title{width:200px;height:20px;background-color: #f1f1f1;}
        .goods-price {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin:6px 0  6px 0;
            font-size: 12px;
            &>div {
                width:80px;
                height:20px;
                background-color: #f1f1f1;
            }
            &>.cart {
                width: 40px;
                height: 40px;
                background-color: #f1f1f1;
            }
        }
    }
    .goods-columns-2 {
        background: #fff;
        width: 166px;
        box-shadow:0px 10px 15px 0px rgba(217,217,217,0.15);
        border-radius:5px;
        padding-bottom:10px;
        .goods-img {
            width: 166px;
            height: 172px;
            border-radius:5px 5px 0px 0px;
            background: #f1f1f1;
        }
        .goods-title {
            width: 146px;
            font-size: 12px;
            letter-spacing: 0.31px;
            line-height: 18px;
            color: #333;
            margin:5px 10px 10px 10px;
            height:40px;
            >p{
                &:nth-of-type(1){width:100%;height:20px;background: #f1f1f1}
                &:nth-of-type(2){width:30%;height:20px;background: #f1f1f1;margin-top:5px}
            }
        }
    }
    .goods-columns-3 {
        background: #fff;
        width: 108px;
        box-shadow:0px 10px 15px 0px rgba(217,217,217,0.15);
        border-radius:5px;
        margin-bottom: 16px;
        .goods-img {
            width: 108px;
            height: 108px;
            border-radius:5px 5px 0px 0px;
            background: #f1f1f1;
        }
        .goods-title {
            width: 88px;
            font-size: 12px;
            letter-spacing: 0.31px;
            line-height: 18px;
            color: #333;
            margin:5px 10px 10px 10px;
            >p{
                &:nth-of-type(1){width:100%;height:20px;background: #f1f1f1;}
                &:nth-of-type(2){width:40%;height:20px;background: #f1f1f1;margin-top:5px}
            }
        }
    }
    @keyframes play {
        from{opacity: 1}
        to{opacity: 0.3}
    }
}
</style>